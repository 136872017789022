<template>
<b-row>
    <b-col sm="12">
      <div class="group-form-card">
          <b-card>
              <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.license_attachments')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                <b-row class="btb_license_file_label">
                  <!-- common licnese upload -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Trade License" vid="trade_license" :rules="attachValidation">
                      <b-form-group
                        label-for="trade_license"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.trade_license') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                            <b-form-file
                            id="trade_license"
                            v-model="application.trade_license"
                            @change="onFileChange($event, 'trade_license')"
                            accept=".pdf"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                            ></b-form-file>
                            <a target="_blank" style="margin-left: 8px;" v-if="application.trade_license && checkBase64(application.trade_license) === false" :href="teaGardenServiceBaseUrl  + application.trade_license" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                          </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- trade license expire fiscal year -->
                  <b-col sm="6">
                    <ValidationProvider name="Validity Period" vid="trade_license_fiscal_year_id" rules="required|min_value:1" v-slot="{ errors }">
                    <b-form-group
                        label-for="trade_license_fiscal_year_id">
                        <template v-slot:label>
                        {{ $t('teaGardenPanel.trade_license_fiscal_year_id') }} <span class="text-danger">*</span>
                        </template>
                          <b-form-select
                              plain
                              v-model="application.trade_license_fiscal_year_id"
                              id="trade_license_fiscal_year_id"
                              :options="fiscalYearList"
                              :state="errors[0] ? false : (valid ? true : null)">
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                        <div class="invalid-feedback d-block" v-if="errors.length">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                 </b-col>
                  <!-- common licnese upload -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
                    <ValidationProvider name="Documents as per organization structure" vid="organization_structure_file" :rules="{required: false}">
                      <b-form-group
                        label-for="organization_structure_file"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.organization_structure_file') }}
                        </template>
                        <div class="d-flex">
                            <b-form-file
                            id="organization_structure_file"
                            v-model="application.organization_structure_file"
                            @change="onFileChange($event, 'organization_structure_file')"
                            accept=".pdf"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                            ></b-form-file>
                            <a target="_blank" style="margin-left: 8px;" v-if="application.organization_structure_file && checkBase64(application.organization_structure_file) === false" :href="teaGardenServiceBaseUrl  + application.organization_structure_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                          </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!--income_tax_certificate -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 34">
                    <ValidationProvider name="Icome Tax Certificate" vid="income_tax_certificate" :rules="attachValidation">
                      <b-form-group
                        label-for="income_tax_certificate"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.income_tax_certificate') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                          <b-form-file
                              id="income_tax_certificate"
                              v-model="application.income_tax_certificate"
                              @change="onFileChange($event, 'income_tax_certificate')"
                              accept=".pdf"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :placeholder="$t('globalTrans.attachment_placeholder')"
                              :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                            ></b-form-file>
                            <a target="_blank" style="margin-left: 8px;" v-if="application.income_tax_certificate && checkBase64(application.income_tax_certificate) === false" :href="teaGardenServiceBaseUrl  + application.income_tax_certificate" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- vat certificate -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 34">
                    <ValidationProvider name="VAT Certificate" vid="vat_certificate" :rules="attachValidation">
                      <b-form-group
                        label-for="vat_certificate"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.vat_certificate') }} <span class="text-danger"> *</span>
                        </template>
                        <div class="d-flex">
                          <b-form-file
                          id="vat_certificate"
                          v-model="application.vat_certificate"
                          @change="onFileChange($event, 'vat_certificate')"
                          accept=".pdf"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.vat_certificate && checkBase64(application.vat_certificate) === false" :href="teaGardenServiceBaseUrl + 'storage' + application.vat_certificate" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- bank solvency certificate -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 34">
                    <ValidationProvider name="Bank Solvency Certificate" vid="bank_solvency_certificate" :rules="attachValidation">
                      <b-form-group
                        label-for="bank_solvency_certificate"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.bank_solvency') }} <span class="text-danger"> *</span>
                        </template>
                        <div class="d-flex">
                          <b-form-file
                          id="bank_solvency_certificate"
                          v-model="application.bank_solvency_certificate"
                          @change="onFileChange($event, 'bank_solvency_certificate')"
                          accept=".pdf"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.bank_solvency_certificate && checkBase64(application.bank_solvency_certificate) === false" :href="teaGardenServiceBaseUrl  + application.bank_solvency_certificate" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!--fire_certificate -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 34">
                    <ValidationProvider name="Fire certificate" vid="fire_certificate" :rules="attachValidation">
                      <b-form-group
                        label-for="fire_certificate"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.fire_certificate') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                          <b-form-file
                              id="fire_certificate"
                              v-model="application.fire_certificate"
                              @change="onFileChange($event, 'fire_certificate')"
                              accept=".pdf"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :placeholder="$t('globalTrans.attachment_placeholder')"
                              :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                            ></b-form-file>
                            <a target="_blank" style="margin-left: 8px;" v-if="application.fire_certificate && checkBase64(application.fire_certificate) === false" :href="teaGardenServiceBaseUrl  + application.fire_certificate" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!--land_owner_rent_paper -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 34">
                    <ValidationProvider name="Land ownership/tenancy documents" vid="land_owner_rent_paper" :rules="attachValidation">
                      <b-form-group
                        label-for="land_owner_rent_paper"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.land_owner_rent_paper') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                          <b-form-file
                              id="land_owner_rent_paper"
                              v-model="application.land_owner_rent_paper"
                              @change="onFileChange($event, 'land_owner_rent_paper')"
                              accept=".pdf"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :placeholder="$t('globalTrans.attachment_placeholder')"
                              :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                            ></b-form-file>
                            <a target="_blank" style="margin-left: 8px;" v-if="application.land_owner_rent_paper && checkBase64(application.land_owner_rent_paper) === false" :href="teaGardenServiceBaseUrl  + application.land_owner_rent_paper" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!--full_sketch_wearhouse -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 34">
                    <ValidationProvider name="Complete sketch map of the warehouse" vid="full_sketch_wearhouse" :rules="attachValidation">
                      <b-form-group
                        label-for="full_sketch_wearhouse"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.full_sketch_wearhouse') }} <span class="text-danger">*</span>
                        </template>
                        <div class="d-flex">
                          <b-form-file
                              id="full_sketch_wearhouse"
                              v-model="application.full_sketch_wearhouse"
                              @change="onFileChange($event, 'full_sketch_wearhouse')"
                              accept=".pdf"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :placeholder="$t('globalTrans.attachment_placeholder')"
                              :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                            ></b-form-file>
                            <a target="_blank" style="margin-left: 8px;" v-if="application.full_sketch_wearhouse && checkBase64(application.full_sketch_wearhouse) === false" :href="teaGardenServiceBaseUrl  + application.full_sketch_wearhouse" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- profile -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
                    <ValidationProvider name="Passport Size Photo" vid="profile_photo" :rules="attachValidation">
                      <b-form-group
                        label-for="profile_photo"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.profile_photo') }} <span class="text-danger"> *</span>
                        </template>
                      <div class="d-flex">
                        <b-form-file
                          id="profile_photo"
                          v-model="application.profile_photo"
                          @change="onFileChange($event, 'profile_photo')"
                          accept=".jpg,.jpeg,.png"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.profile_photo && checkBase64(application.profile_photo) === false" :href="teaGardenServiceBaseUrl + 'storage' + application.profile_photo" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_image_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_image_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!--blender license-->
                  <template v-if="application.service_id === 31">
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="BSTI Certificate" vid="bsti_certificate" :rules="attachValidation">
                        <b-form-group
                          label-for="bsti_certificate"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('teaGardenPanel.bsti_certificate') }} <span class="text-danger"> *</span>
                          </template>
                        <div class="d-flex">
                          <b-form-file
                            id="bsti_certificate"
                            v-model="application.bsti_certificate"
                            @change="onFileChange($event, 'bsti_certificate')"
                            accept=".jpg,.jpeg,.png"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                          ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.bsti_certificate && checkBase64(application.bsti_certificate) === false" :href="teaGardenServiceBaseUrl + 'storage' + application.bsti_certificate" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                          </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_image_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_image_1')}}</b></small>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Factory Photo" vid="factory_image" :rules="attachValidation">
                        <b-form-group
                          label-for="factory_image"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('teaGardenPanel.factory_photo') }} <span class="text-danger"> *</span>
                          </template>
                        <div class="d-flex">
                          <b-form-file
                            id="factory_image"
                            v-model="application.factory_image"
                            @change="onFileChange($event, 'factory_image')"
                            accept=".jpg,.jpeg,.png"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                          ></b-form-file>
                            <a target="_blank" style="margin-left: 8px;" v-if="application.factory_image && checkBase64(application.factory_image) === false" :href="teaGardenServiceBaseUrl + 'storage' + application.factory_image" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                          </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_image_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_image_1')}}</b></small>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Packet Sample" vid="packet_image" :rules="attachValidation">
                        <b-form-group
                          label-for="packet_image"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('teaGardenPanel.packet_sample') }} <span class="text-danger"> *</span>
                          </template>
                        <div class="d-flex">
                          <b-form-file
                            id="packet_image"
                            v-model="application.packet_image"
                            @change="onFileChange($event, 'packet_image')"
                            accept=".jpg,.jpeg,.png"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                          ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.packet_image && checkBase64(application.packet_image) === false" :href="teaGardenServiceBaseUrl + 'storage' + application.packet_image" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                          </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_image_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_image_1')}}</b></small>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <ValidationProvider name="Appointed Tea Tester Biodata" vid="tea_tester_biodata" :rules="attachValidation">
                        <b-form-group
                          label-for="tea_tester_biodata"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('teaGardenPanel.tea_tester_biodata') }} <span class="text-danger"> *</span>
                          </template>
                        <div class="d-flex">
                          <b-form-file
                            id="tea_tester_biodata"
                            v-model="application.tea_tester_biodata"
                            @change="onFileChange($event, 'tea_tester_biodata')"
                            accept=".jpg,.jpeg,.png"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.attachment_placeholder')"
                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                          ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.tea_tester_biodata && checkBase64(application.tea_tester_biodata) === false" :href="teaGardenServiceBaseUrl + 'storage' + application.tea_tester_biodata" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                          </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_image_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_image_1')}}</b></small>
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </template>
                  <!--bidder license-->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 32">
                    <ValidationProvider name="Retail-Wholesale License" vid="retail_wholesale_license" :rules="attachValidation">
                      <b-form-group
                        label-for="retail_wholesale_license"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.retail_wholesale_license') }} <span class="text-danger"> *</span>
                        </template>
                      <div class="d-flex">
                        <b-form-file
                          id="retail_wholesale_license"
                          v-model="application.retail_wholesale_license"
                          @change="onFileChange($event, 'retail_wholesale_license')"
                          accept=".jpg,.jpeg,.png"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                        <a target="_blank" style="margin-left: 8px;" v-if="application.retail_wholesale_license && checkBase64(application.retail_wholesale_license) === false" :href="teaGardenServiceBaseUrl + 'storage' + application.retail_wholesale_license" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_image_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_image_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- directors biodata -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 30">
                    <ValidationProvider name="Director's Biodata" vid="director_biodata" :rules="attachValidation">
                      <b-form-group
                        label-for="director_biodata"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.director_biodata') }} <span class="text-danger"> *</span>
                        </template>
                    <div class="d-flex">
                      <b-form-file
                          id="director_biodata"
                          v-model="application.director_biodata"
                          @change="onFileChange($event, 'director_biodata')"
                          accept=".pdf"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                        <a target="_blank" style="margin-left: 8px;" v-if="application.director_biodata && checkBase64(application.director_biodata) === false" :href="teaGardenServiceBaseUrl  + application.director_biodata" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- garden owner certificate -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 30">
                    <ValidationProvider name="Certificate from Garden Owners" vid="garden_owner_certificate" :rules="attachValidation">
                      <b-form-group
                        label-for="garden_owner_certificate"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.garden_owner_certificate') }} <span class="text-danger"> *</span>
                        </template>
                    <div class="d-flex">
                      <b-form-file
                          id="garden_owner_certificate"
                          v-model="application.garden_owner_certificate"
                          @change="onFileChange($event, 'garden_owner_certificate')"
                          accept=".pdf"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.garden_owner_certificate && checkBase64(application.garden_owner_certificate) === false" :href="teaGardenServiceBaseUrl  + application.garden_owner_certificate" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- agreement paper -->
                  <b-col xs="6" sm="6" v-if="application.service_id === 30">
                    <ValidationProvider name="Undertaking not to be involved in any other type of tea business in the name / anonymous" vid="agreement_paper" :rules="attachValidation">
                      <b-form-group
                        label-for="agreement_paper"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('teaGardenPanel.agreement_paper') }} <span class="text-danger"> *</span>
                        </template>
                      <div class="d-flex">
                        <b-form-file
                          id="agreement_paper"
                          v-model="application.agreement_paper"
                          @change="onFileChange($event, 'agreement_paper')"
                          accept=".pdf"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                          <a target="_blank" style="margin-left: 8px;" v-if="application.agreement_paper && checkBase64(application.agreement_paper) === false" :href="teaGardenServiceBaseUrl  + application.agreement_paper" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <!-- signature -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
                    <ValidationProvider name="Chief Executive's Signature" vid="signature" :rules="attachValidation">
                      <b-form-group
                        label-for="signature"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          <span v-if="application.service_id === 30">{{ $t('teaGardenPanel.chief_executives_signature') }}</span>
                          <span v-if="application.service_id != 30">{{ $t('teaGardenPanel.applicant_signature') }}</span>
                          <span class="text-danger"> *</span>
                        </template>
                      <div class="d-flex">
                        <b-form-file
                          id="signature"
                          v-model="application.signature"
                          @change="onFileChange($event, 'signature')"
                          accept=".jpg,.jpeg,.png"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('globalTrans.attachment_placeholder')"
                          :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                        ></b-form-file>
                        <a target="_blank" style="margin-left: 8px;" v-if="application.signature && checkBase64(application.signature) === false" :href="teaGardenServiceBaseUrl  + 'storage' + application.signature" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                        </div>
                        <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_image_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_image_1')}}</b></small>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-form-group>
          </b-card>
          <div class="mb-2" style="font-size: 12px">
           <div v-html="`${currentLocale == 'bn' ? serviceInstruction?.instruction_bn : serviceInstruction?.instruction_en}`"></div>
          </div>
      </div>
  </b-col>
</b-row>
</template>
<script>
import { teaGardenServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'AttachmentForm',
  props: ['application'],
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      errors: [],
      serviceInstruction: '',
      attachValidation: 'required'
    }
  },
  created () {
    if (this.id) {
      this.attachValidation = ''
      this.application.trade_license = ''
    }
    if (this.application.service_id) {
      if (this.$store.state.Auth.authUser.user_type === 2) {
         this.serviceInstruction = this.$store.state.ExternalUserService.teaGarden.commonObj.masterServiceWiseInstructionList.find(item => item.service_id === this.application.service_id)
      }
      if (this.$store.state.Auth.authUser.user_type === 1) {
         this.serviceInstruction = this.$store.state.TeaGardenService.commonObj.masterServiceWiseInstructionList.find(item => item.service_id === this.application.service_id)
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).sort((a, b) => b.value - a.value).slice(0, 3)
    }
  },

  methods: {
     onFileChange (event, property) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.application[property] = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.application[property] = ''
        }
     },
     checkBase64 (string) {
        var result = ''
        result = string.match('data:')
        if (result) {
            return true
        } else {
            return false
        }
     }
  }
}
</script>
<style>
.font-bn .btb_license_file_label .custom-file-label {
  font-size: 14px;
  color: #7c7c7c;
}
.validation_msg {
  font-size: 11px;
}
</style>
